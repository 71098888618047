import React from "react";
import DotSeparator from "./DotSeparator";
import { scroll } from "../../../../../utils/helpers/scroll";
import styles from "./SellerInfo.scss";

const SellerInfo = ({ davinci }) => {
  const onClickMerchantInfo = () => {
    scroll("#About-seller", "auto");
    davinci?.clickMerchantInformationButtonEvent();
  };

  return (
    <div className={styles.sellerInfo}>
      <DotSeparator />
      <span className={styles.text} onClick={onClickMerchantInfo}>
        Satıcı bilgileri
      </span>
    </div>
  );
};

export default SellerInfo;
