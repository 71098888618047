// Components
import React, { useRef, useState, useCallback, useEffect } from "react";
import classnames from "classnames";
import { useRowContext } from "../../../../contexts/RowContext";
import FollowButton from "../../../components/FollowButton/FollowButton";
import ModalManager from "../../../components/FollowButton/ModalManager/ModalManager";
import MobileSearch from "../../../components/MobileSearch/MobileSearch";
// Services
import DaVinciService from "../../../../services/davinciService";
// Helpers && Constants && Styles
// import { scroll } from "../../../../utils/helpers/scroll";
import { DAVINCI_PAGE_MERCHANT } from "../../../../utils/constants";
import { isUserLoggedIn } from "../../../../utils/helpers/cookie";
import { getIsFollowingMerchant } from "../../../../services/followerService";
import { getDailyCouponLimit } from "../../../../services/couponService";
import appConfig from "../../../../appConfig";
import MerchantTag from "../../../components/RowComponents/BannerRow/MerchantTag/MerchantTag";
import { displayedNameFormatted } from "../utils";
import BlankProfileImage from "../MerchantRowDesktop/common/BlankProfileImage";
import MerchantRating from "../MerchantRowDesktop/common/MerchantRating";
import MerchantFollower from "../MerchantRowDesktop/common/MerchantFollower";
import MerchantProducts from "../MerchantRowDesktop/common/MerchantProducts";
import MerchantEvaluation from "../MerchantRowDesktop/common/MerchantEvaluation";
import WomanEnt from "../MerchantRowDesktop/common/WomanEnt";
import SellerInfo from "../MerchantRowDesktop/common/SellerInfo";
import { TbackgroundType } from "../helpers/types";
import StickyCoupon from "../MerchantRowDesktop/common/StickyCoupon";
import { getMerchantTagData, getMerchantTagType } from "../../../../utils/helpers/merchantTag";
import testIdMappings from "../../../../../conf/testIdMappings.conf";

function McRowMobile({ initialState, styles }) {
  const mobileModalRef = useRef(null);
  const [followerCount, setFollowerCount] = useState(initialState.data.followerCount);
  const [followButtonClicked, setFallowButtonClicked] = useState(false);
  const [isThereAuthErr, setIsThereAuthErr] = useState(false);

  const { setIsFollowBtnLoading, setFollowingStatus, setDailyCouponLimit, setCanBeFollowing } = useRowContext();
  const { merchantDetail, totalProductCount = 0, merchantRowBackground, backgroundType } = initialState.data;
  const davinci = DaVinciService(merchantDetail, DAVINCI_PAGE_MERCHANT);
  const isWomenEnt = merchantDetail?.tagList?.some(i =>
    ["woman-entrepreneur", "woman-entrepreneur-logo-biography"].includes(i)
  );

  const merchantTagData = getMerchantTagData(getMerchantTagType(merchantDetail?.tagList));
  const merchantPartners = merchantDetail?.partners;

  const isThereBgColor = merchantRowBackground?.color !== "#FFFFFF" && !!merchantRowBackground?.color;
  const isThereBg = isThereBgColor || !!merchantRowBackground?.imageUrl;
  const isContentDisable = merchantDetail?.tagList?.some(item => item.includes("content-disable-row-image"));

  const blankStyleVisibility = () => {
    if (isThereBgColor) {
      return false;
    } else {
      if (isContentDisable) {
        return true;
      }
      return !isThereBg;
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (isThereAuthErr) {
      window.location.href = `${appConfig.sfLoginUrl}?ReturnUrl=${appConfig.sfBaseUrl}${window?.location?.pathname}`;
    }
  }, [followButtonClicked]);

  // Fetch following status, daily coupon limit.
  const fetchInitialData = async () => {
    if (!isUserLoggedIn()) {
      setIsFollowBtnLoading(false);
      setIsThereAuthErr(true);
      setCanBeFollowing(false);
      return;
    }

    try {
      const promises = [getIsFollowingMerchant(merchantDetail.id)];

      // If merchant has a coupon, check user daily limit.
      if (merchantDetail.coupon) promises.push(getDailyCouponLimit());

      const result = await Promise.all(promises);
      setFollowingStatus({ ...result[0]?.data?.data });
      setDailyCouponLimit(result[1]?.data?.customerDailyCouponsLimit);
      setCanBeFollowing(true);
    } catch (error) {
      if (error.toString().includes(401)) {
        setIsThereAuthErr(true);
        setCanBeFollowing(false);
      }
    } finally {
      setIsFollowBtnLoading(false);
    }
  };

  const onHoverMerchantBadge = () => {
    davinci.clickHoverRatingEvent();
  };

  const onHoverMerchantTag = () => {
    davinci.clickHoverMerchantTagEvent(merchantTagData?.itemName, merchantPartners);
  };

  const onMerchantTagVisible = () => {
    davinci.viewMerchantTagEvent(merchantTagData?.itemName, merchantPartners);
  };

  const openRatingModal = useCallback(() => {
    onHoverMerchantBadge();
    mobileModalRef.current.toggleModalVisibility(true, "rating");
  }, []);

  const openMerchantTagModal = useCallback(() => {
    onHoverMerchantTag();
    mobileModalRef.current.toggleModalVisibility(true, "merchantTag");
  }, []);

  const row = (
    <div className={styles.row}>
      <div className={styles.rowContainer}>
        <div className={styles.imgWrapper}>
          {!!merchantDetail?.logoUrl == false && <BlankProfileImage isMobile isThereBg={!blankStyleVisibility()} />}

          {merchantDetail?.logoUrl && (
            <section className={styles.imgContainer}>
              <img className={styles.img} src={merchantDetail.logoUrl} alt='group-circle' />
            </section>
          )}
        </div>
        <section className={styles.main}>
          <div className={styles.head}>
            <h1 className={styles.merchantName} data-test-id={testIdMappings.h1}>
              {displayedNameFormatted(merchantDetail.displayedName, 15)}
            </h1>

            {(!!merchantTagData || !!merchantDetail?.partners) && (
              <MerchantTag
                onClick={openMerchantTagModal}
                onMerchantTagVisible={onMerchantTagVisible}
                calling='mobile'
                hasPartners={!!merchantDetail?.partners}
                tagData={{ ...merchantTagData, partners: merchantDetail?.partners }}
              />
            )}
          </div>

          <div className={styles.ratingAndFollower}>
            <MerchantRating davinci={davinci} merchantDetail={merchantDetail} onClick={openRatingModal} />
            <MerchantFollower followerCount={followerCount} />
          </div>
        </section>
        <div className={styles.buttonContainer}>
          <FollowButton
            merchantDetail={merchantDetail}
            setFollowerCount={setFollowerCount}
            currentPage={DAVINCI_PAGE_MERCHANT}
            device='mobile'
            onClicked={() => setFallowButtonClicked(true)}
          />
        </div>
      </div>
      <div className={styles.rowFooter} id='merchantSubInfos'>
        <MerchantProducts totalProductCount={totalProductCount} />
        <MerchantEvaluation numOfEvaluations={merchantDetail?.rating?.feedbackCount} />
        <SellerInfo davinci={davinci} />
        <WomanEnt isWomenEnt={isWomenEnt} />
      </div>
    </div>
  );

  const handleStyleBg = () => {
    if (backgroundType === TbackgroundType.colorBg) return merchantRowBackground?.color;
    if (backgroundType === TbackgroundType.imageBg) return `url(${merchantRowBackground?.imageUrl})`;
  };
  return (
    <>
      <div
        className={classnames(styles.rowBg, {
          [styles.withTwoPicture]: !!merchantDetail?.logoUrl && !!merchantDetail?.profilePicture
        })}
        style={{
          background: handleStyleBg()
        }}
      >
        {backgroundType === TbackgroundType.blankBg && <div className={styles.blankBgRowRoot}>{row}</div>}
      </div>

      {backgroundType !== TbackgroundType.blankBg && (
        <div
          className={classnames({
            [styles.rowRootBgImg]: backgroundType === TbackgroundType.imageBg,
            [styles.rowRootBgColor]: backgroundType === TbackgroundType.colorBg
          })}
        >
          {row}
        </div>
      )}

      <StickyCoupon merchantId={merchantDetail?.id} />
      <MobileSearch merchantDetail={merchantDetail} />

      <ModalManager ref={mobileModalRef} merchantDetail={merchantDetail} device={"mobile"} />
    </>
  );
}
export default McRowMobile;
