const voltran = require("/home/node/app/node_modules/voltranjs/src/index");
import React from "react";
import cx from "classnames";
// Components
import McRowMobile from "./McRowMobile";
import { RowContextProvider } from "../../../../contexts/RowContext";
// Helpers && Constants && Styles
import { getInitialData } from "../helpers/initialData";
import { renderRowComponents } from "../helpers/render";
import ROUTE_PATHS from "../../../../routes/routeConstants";
import styles from "./MerchantRowMobile.scss";
import { TbackgroundType } from "../helpers/types";
import { SPECIAL_COMPONENTS_WITH_MERCHANT_TAGS } from "Utils/constants";

const MerchantRowMobile = ({ initialState }) => {
  const { rows, ids, merchantDetail, backgroundType, hbAdultValue, isBot } = initialState.data;
  let filteredIds = [];
  let filteredRows = {};
  filteredRows = { ...rows };
  filteredIds = ids?.length > 0 ? [...ids] : [];
  SPECIAL_COMPONENTS_WITH_MERCHANT_TAGS.forEach(item => {
    if (merchantDetail?.tagList.includes(item.tag) === item.willBeDelete) {
      ids?.forEach(id => {
        if (item.componentSubTypeList.includes(rows[id]?.subType)) {
          delete filteredRows[id];
          filteredIds = filteredIds.filter(item => item !== id);
        }
      });
    }
  });

  const classNameListWrapper = {
    [styles.wrapper__blankBg]: backgroundType === TbackgroundType.blankBg,
    [styles.wrapper__imgBg]: backgroundType === TbackgroundType.imageBg,
    [styles.wrapper__colorBg]: backgroundType === TbackgroundType.colorBg
  };

  return (
    <RowContextProvider>
      <div className={cx(classNameListWrapper)}>
        <McRowMobile initialState={initialState} styles={styles} />

        {filteredRows &&
          Object.keys(filteredRows)?.length > 0 &&
          filteredIds?.length > 0 &&
          filteredIds?.map((id, index) =>
            renderRowComponents(filteredRows[id], index, merchantDetail, backgroundType, undefined, hbAdultValue, undefined, isBot)
          )}
      </div>
    </RowContextProvider>
  );
};

const component = voltran.default.withBaseComponent(MerchantRowMobile, ROUTE_PATHS.MERCHANTROWMOBILE);

component.services = [voltran.default.SERVICES.merchantContentApi, voltran.default.SERVICES.product];

component.getInitialState = getInitialData;

export default component;
