import React, { useState, useEffect } from "react";
import DaVinciService from "../../../services/davinciService";
import styles from "./MobileSearch.scss";

function MobileSearch({ merchantDetail }) {
  const [searchText, setSearchText] = useState("");
  const davinci = DaVinciService(merchantDetail, "Merchant Page");

  let searchParams;

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    setSearchText(params.get("q") ? params.get("q") : "");
  }, []);

  const handleSearchMerchant = () => {
    if (searchText.trim().length <= 1) {
      return;
    }
    davinci.searchInStoreEvent(searchText);
    searchParams = new URLSearchParams(window.location.search);
    searchParams.set("q", searchText.trim());
    window.location.search = searchParams.toString();
  };

  const handleClearSearchText = () => {
    searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("q")) {
      searchParams.delete("q");
      window.location.search = searchParams.toString();
    } else {
      setSearchText("");
    }
  };

  return (
    <div className={styles.mobileSearch}>
      <div onClick={handleSearchMerchant} className={styles.searchIcon}></div>
      <input
        type='text'
        placeholder='Mağazada ara'
        value={searchText || ""}
        onChange={({ target }) => setSearchText(target.value)}
        onKeyPress={({ key }) => key === "Enter" && handleSearchMerchant()}
      />
      {!!searchText.trim().length && <div onClick={handleClearSearchText} className={styles.clearIcon}></div>}
    </div>
  );
}

export default MobileSearch;
