/** SERVICES */
import { getFollowersCountOfMerchantInternal } from "../../../../services/followerService";
import { getMerchantDataWithRatingAndCouponByIdInternal } from "../../../../services/merchantService";
import { getPublishedLayoutByMerchantIdInternal } from "../../../../services/layoutService";
/** UTILS */
import { throwError } from "../../../../utils/errorHandler/errorHandler";
import { checkSearchParamExistsExceptAllowed } from "../../../../utils/helpers/browser";
import { checkMerchantIdExists, checkMerchantIsActiveOrNot, getDisplayMerchantName } from "./merchant";

export const getInitialData = async (voltranApiManager, productVoltranApiManager, context) => {
  // Do not remove "preview"
  /* eslint-disable-next-line no-unused-vars */
  const { preview, merchantId, merchantName, totalProductCount, h1title, isBot, ...rest } = context.query;

  checkMerchantIdExists(merchantId);
  const searchParamsExist = checkSearchParamExistsExceptAllowed(context.query);

  const ABTestingParams = {
    experimentCookie: rest?.experimentCookie,
    anonId: rest?.anonId,
    userId: rest?.userId,
  };

  try {
    // Add this get operation to settleAll after SF Team gives merchant name directly
    const requestArray = [
      // Get merchant data by id
      getMerchantDataWithRatingAndCouponByIdInternal(merchantId, voltranApiManager, context.cookies),
      // Get total followers of the merchant
      getFollowersCountOfMerchantInternal(merchantId, voltranApiManager, context.cookies),
      // Get layout of the merchant
      getPublishedLayoutByMerchantIdInternal(
        merchantId,
        searchParamsExist,
        voltranApiManager,
        context.cookies,
        undefined,
        ABTestingParams
      ),
    ];

    const response = await Promise.allSettled(requestArray);
    const merchantRowBackground = response[2]?.value?.data?.data?.background;
    const isThereBgColor = merchantRowBackground?.color !== "#FFFFFF" && !!merchantRowBackground?.color;
    const isThereBg = isThereBgColor || !!merchantRowBackground?.imageUrl;
    const isContentDisable = response[0]?.value?.data?.data?.tagList?.some((item) =>
      item.includes("content-disable-row-image")
    );

    const backgroundType = () => {
      if (isThereBgColor) {
        return "colorBg";
      } else {
        if (isContentDisable) {
          return "blankBg";
        }
        return isThereBg ? "imageBg" : "blankBg";
      }
    };
    const data = {
      merchantId,
      merchantDetail: response[0]?.value?.data?.data,
      followerCount: response[1]?.value?.data?.data?.count,
      merchantRowBackground: response[2]?.value?.data?.data?.background,
      rows: searchParamsExist ? [] : response[2]?.value?.data?.data?.rows,
      ids: response[2]?.value?.data?.data?.ids,
      totalProductCount,
      backgroundType: backgroundType(),
      isBot: !!isBot && (isBot === true || isBot === 'true'),
    };

    if (data) {
      checkMerchantIsActiveOrNot(data);
      data.merchantDetail.displayedName = getDisplayMerchantName(data.merchantDetail, h1title);

      return data;
    }
  } catch (error) {
    throwError(error, context);
  }
};
